.performance-table-container {
  .performance-table {
    tr:hover {
      background-color: white;
    }

    tr:nth-of-type(even) {
      background: $highlight-blue;
    }
    td,
    th {
      padding: 3px 0.5px;
      text-align: center;
      border: 1px solid $eggshell !important;
      &:first-of-type {
        font-weight: bold;
      }
      &:last-of-type {
        font-weight: bold;
      }
      color: $navbar-background-color;
    }

    th {
      background-color: $ice-blue;
      color: $ocean-navy;
      border: 1px solid #ddd;
    }
    .k-alt {
      background-color: $table-alter-row;
      &:hover {
        background-color: $table-alter-row;
      }
    }
  }
  .performance-legend {
    display: flex;
    justify-content: flex-end;
    margin: 0 10px;
    span {
      margin: 0 20px;
      padding: 5px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .underlying-adjusted {
    color: #6d6d6d !important;
  }
  .estimate,
  .actual {
    color: $antarctica-color !important;
    font-weight: bolder;
  }
  .estimate {
    font-style: italic;
  }
}
