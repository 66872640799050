.new-story-container {
  width: 100%;
  .editor-container {
    margin: 0 auto;
    max-width: 768px;
    display: flex;
    flex-direction: column;
    .mode-button {
      background-color: white;
      margin-bottom: 16px;
    }
    .single-editor {
      margin-bottom: 8px;
      width: 100%;
    }
    .k-textbox-container {
      margin-bottom: 20px;
    }
    .dropzone {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px;
      border-width: 2px;
      border-radius: 2px;
      border-color: #eeeeee;
      border-style: dashed;
      background-color: #fafafa;
      color: #bdbdbd;
      outline: none;
      transition: border 0.24s ease-in-out;
    }
    .title-row {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }
  }
}
