.plain-table-container {
  margin-top: 12px;
  .linked {
    cursor: pointer;
  }
  th {
    padding: 6px 3px;
    &:first-of-type {
      padding: 6px 16px;
    }
  }
  td {
    padding: 6px 3px;
    &:first-of-type {
      padding: 6px 16px;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 0;
    }
    &:not(:first-of-type) {
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
