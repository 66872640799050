.documents-container {
  color: $navbar-background-color;
  .upload-button {
    .k-button {
      background-color: $antarctica-color;
      color: white;
      border-radius: 5px;
    }
  }
  .documents-table {
    .k-grid-header {
      background-color: $antarctica-color;
      color: white;
    }
  }
}
