.user-activity-auth {
  .auth-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px 0;
  }
}
.user-activity-route {
  margin: 8px 0;
  .selectors {
    margin: 8px 0;
    display: flex;
    justify-content: center;
    .selector-form {
      display: flex;
      flex-direction: column;
      .item {
        margin: 2px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
