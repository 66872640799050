.portfolios-container {
  .k-toolbar {
    min-height: 60px;
  }
  .portfolios-table {
    height: auto !important;
    .toolbar {
      display: flex;
      align-items: center;
      .name-filter {
        flex: 0 1 250px;
      }
      .k-dropdown {
        flex: 0 1 250px;
      }
      .new-portfolio-button {
        color: white;
        margin-left: auto;
      }
    }
    .k-grid-header {
      background-color: $antarctica-color;
      color: white;
      $hovered-text: grey;
    }
    .k-alt {
      background-color: $table-alter-row !important;
    }
    tr {
      font-size: small;
    }
    td {
      text-align: center;
      &:nth-of-type(1) {
        text-align: left;
      }
    }

    thead {
      th {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        text-align: center;
        &:hover {
          background-color: $antarctica-light-color;
          & * {
            color: white !important;
          }
        }
        &:nth-of-type(1) {
          text-align: left;
          padding-left: 14px;
        }
      }
      .k-sorted {
        background-color: $antarctica-light-color;
        & * {
          color: white !important;
        }
      }
    }

    .portfolio-name-row {
      cursor: pointer !important;
      width: 400px;
      padding-left: 14px;
      &:hover {
        font-weight: bold;
      }
      .portfolio-name {
        color: $antarctica-color;
        padding: 0;
      }
      .portfolio-desc {
        padding: 0;
      }
    }
  }
}
