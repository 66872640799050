.trading-calendar-route {
  max-width: 1440px;
  margin: 16px auto;
  @media only screen and (min-width: $media-large) {
    max-width: 1796px;
  }
  .k-grid-header {
    th {
      background-color: $antarctica-color;
      color: white;
      white-space: normal;
    }
  }
  .k-sorted {
    th {
      background-color: $antarctica-light-color !important;
      color: white !important;
      white-space: normal !important;
    }
  }
  .k-grid-content {
    td {
      &:first-of-type {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

// overflow: hidden;
// text-overflow: ellipsis;
// white-space: nowrap;
