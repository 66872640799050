.player-container {
  max-width: 700px;
  margin: 0 auto;
  .player-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    
    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.dash-cards {
    align-items: stretch;

  .player-card {
    max-width: 640px;
    width: 100%;
  }
  .player-wrapper {
    max-width: 700px;
    position: relative;
    width: 100%;
    padding-top: 56.25%; // 6:9
  }


  .demo-button {
    background-color: $red-accent;
    border-radius: 0;
    color: white;
    font-size: 16px;
    height: 48px;
    text-align: center;
    text-transform: none !important;
    width: 217px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    .greeting {
      padding: 20px;
      color: $navbar-background-color;
    }
  }

  .MuiTypography-root {
    color: $navbar-background-color;
  }

  .MuiCard-root {
    height: 100%;
    .card-title {
      display: flex;
      align-items: center;
      // margin-bottom: 5px;

      .view-all-button {
        margin-left: auto;
      }
    }
  }

  .recent-stories {
    .card-title {
      margin-bottom: 12px;
      .MuiTypography-root {
        padding-bottom: 4px;
      }
      .view-all-button {
        margin-left: auto;
      }
    }

    .stories-content {
      display: flex;
      flex-direction: row;
      margin-bottom: 12px;

      .story-description {
        margin-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .story-picture {
        img {
          height: 100px;
          width: 120px;
          object-fit: cover;
        }
        @media only screen and (max-width: $media-large) {
          img {
            height: 33.33px;
            width: 40px;
            object-fit: cover;
          }
        }
      }
      .story-content-right {
        margin: 0 0 0 16px;
        .story-name {
          .story-link {
            color: $ocean-navy;
            text-decoration: none;
            font-weight: bold;
          }
        }
        .story-date {
          margin-bottom: 0px;
        }
      }
    }
  }

  .highlights-table {
    margin-top: 12px;
    .linked {
      cursor: pointer;
    }
    tbody {
      td {
        &:first-of-type {
          max-width: 190px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-right: 0;
        }
        &:last-of-type {
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  @media only screen and (max-width: $media-large) {
    .MuiTableCell-sizeSmall {
      font-size: 12px;
    }
    .MuiTypography-subtitle2 {
      font-size: 12px;
    }
  }
}
