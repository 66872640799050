.performance-overview-table {
  .generic-table-container {
    .year-filter {
      flex: 0 1 200px;
    }
    .portfolio-filter {
      flex: 0 1 400px;
    }
    .strategy-filter {
      flex: 0 1 400px;
    }
    .k-toolbar {
      min-height: 60px;
    }
    .toolbar {
      display: flex;
      align-items: center;
      .MuiFormControl-root {
        width: unset !important;
      }
      .portfolio-filter {
        width: 250px;
      }
      .name-filter {
        width: 350px;
        margin-right: 30px;
      }
      .strategy-filter {
        width: 300px;
      }
    }
  }
}
