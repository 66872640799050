.content-main {
  display: flex;
  // font-family: "Merriweather", serif !important;
  margin: 0 auto;
  max-width: 1100px;

  .article-main {
    margin: 20px;
    max-width: 768px;
    color: $navbar-background-color;
    line-height: 1.6;
    .article-image {
      img {
        height: 100%;
        width: 100%;
      }
    }

    .text-only {
      padding-top: 10px;
      font-size: 14px;
    }
    .article-title {
      font-size: 22px;
      font-weight: bold;
      padding-top: 0;
    }
    .article-date-main {
      font-size: 14px;
      color: #64696e;
      padding-bottom: 12px;
    }
    .article-attachment {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .side-content {
    margin: 0 0 0 30px;
    height: auto;
    width: 420px;
  }
  .recent-posts {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    height: auto;
    width: 400px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 10px;
  }
  .card-image {
    img {
      height: 100px;
      width: 120px;
      object-fit: cover;
    }
  }
  .card-content-right {
    margin: 0 13px;
  }
  .card-title {
    overflow: hidden;
    font-size: 14px;
    margin-top: 0;
  }

  h3 {
    margin-top: 20px;
    color: $navbar-background-color;
  }
  .side-header h3 {
    border-bottom: 2px solid $navbar-background-color;
  }

  a {
    text-decoration: none;
    color: $navbar-background-color;
  }
  a:hover {
    color: #1a0dab;
  }
  .card-date {
    padding: 0;
    font-size: 12px;
    color: #8a9199;
  }
}
