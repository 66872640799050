.exposure-chart-report {
  .top-level-ts-title,
  .top-level-cat-title {
    position: relative;
    text-align: center;
    .periodicity-select {
      width: 150px !important;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .exposure-selector {
    display: flex;
    background-color: $antarctica-light-color;
    .MuiTypography-root {
      color: white;
    }
    .kendo-virtual-combobox {
      margin: 0 10px;
      width: 150px !important;
    }
  }
}
