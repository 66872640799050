.exposure-map-route {
  max-width: 1280px;
  margin: 50px auto;

  .exposure-map {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin-top: 20px;
    .row {
      flex: 1;
      display: flex;
    }
    .submit {
      margin-top: 20px;
      // justify-content: flex-end;
      // button {
      // max-width: 50%;
      // }
    }
  }
}
