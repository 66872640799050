.asset-detail-page {
  overflow: hidden !important;
  max-width: 1280px;
  margin: 0 auto;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
