.filter-dialog {
  .MuiPaper-root {
    min-height: 70vh;
    min-width: 400px;
    .filter-form-content {
      .MuiFormControl-root {
        padding: 5px 0;
      }
    }
  }
}
