.operations-container {
  display: flex;
  flex-direction: column;
  .MuiTypography-root {
    padding: 10px;
  }
  .flows {
    display: flex;
    flex-direction: row;
    margin-bottom: 1%;
    .manager-flows {
      width: 50%;
      margin-right: 1%;
    }
    .investor-flows {
      width: 50%;
    }
  }
  .cash-flow {
    display: flex;
    flex-direction: row;
    margin-bottom: 1%;
    .current-cash {
      width: 50%;
      margin-right: 1%;
    }
    .projected-cash {
      width: 50%;
    }
  }
  .diagnostics {
    display: flex;
    flex-direction: row;
    .tracking-error {
      width: 50%;
      margin-right: 1%;
    }
    .expense-ratio {
      width: 50%;
    }
  }
}
