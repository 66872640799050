.evaluations-overview-route {
  max-width: 1280px;
  margin: 0 auto;
  .toolbar {
    .k-dropdown {
      width: 256px !important;
    }
  }
  .k-grid-header {
    padding-right: 0;
    th {
      background-color: $antarctica-color;
      color: white;
      white-space: normal;
    }
  }
  .k-grid-content {
    overflow: auto;
    td {
      &:first-of-type {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
