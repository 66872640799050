.root {
  &:not([data-checked='true']) {
    input {
      background: transparent !important;
    }
  }

  input[data-indeterminate='true'] {
    background: var(--mantine-color-sapphire) !important;
}

  svg {
    transition: none;
  }
}