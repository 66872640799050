.performance-update-route {
  margin: 50px auto;
  max-width: 1280px;
  // .performance-update-container {
  //   max-width: 200px;
  //   margin: 0 auto;
  //   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  //   background-color: white;
  // }
  // .submit-button {
  //   margin-top: 20px;
  //   display: flex;
  //   justify-content: center;
  // }
}
