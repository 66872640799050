.key-stats {
  display: flex;
  background-color: $ocean-navy;
  color: white;
  height: 100px;

  .stats-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .MuiTypography-subtitle1 {
      color: $antarctica-light-background;
    }
  }

  .middle {
    border-left: solid;
    border-right: solid;
    border-width: 3px;
    border-color: $body-background;
  }
}
