.security-container {
  display: flex;
  .two-fa-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    .qr-code {
      align-self: center;
    }
    .confirm-button {
      margin: 20px 0;
    }
  }
  .emergency-token-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    .token-list-container {
      display: flex;
      .token-list {
        flex: 1;
      }
    }
  }
}
