.root {
  &::placeholder {
    color: var(--mantine-color-skyBlue);
  }
}

.input {
  display: flex;
  min-height: 48px;
  font-size: 16px;
  color: var(--mantine-color-skyBlue);
  border-color: var(--mantine-color-skyBlue);
  padding-left: 16px;

  &:focus {
    border-color: var(--mantine-color-skyBlue);
  }

  &::placeholder {
    color: var(--mantine-color-skyBlue);
  }

  &.mantine-Input-section {
    color: var(--mantine-color-skyBlue) !important;
  }
}

.error {
  border-color: 'red';
}

.wrapper {
  svg {
    color: var(--mantine-color-skyBlue);
  }
}