.hot-performance {
  .control-panel {
    display: flex;
    justify-content: center;
    margin: 16px 0;
    .reset-button {
      margin: 0 20px;
    }
  }
  .table-container {
    max-width: 200px;
    margin: 0 auto;
    // max-height: 400px;
  }
  .submit-container {
    margin: 16px 0;
    display: flex;
    justify-content: center;
  }
}
