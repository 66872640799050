.profile-header {
  display: flex;
  justify-content: flex-end;
}
.profile-fields {
  display: flex;
  .profile-info-block {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
.profile-actions {
  margin: 20px 0;
  display: flex;
  align-content: flex-end;
  .profile-info-block {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
