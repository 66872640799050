.panel-container {
  padding-bottom: 12px;
  .cell-name {
    color: $navbar-background-color;
    size: small;
    .calendar-icon {
      display: inline;
      padding-left: 10px;
      padding-top: 10px;
    }
  }
  .class-level {
    .aum-chart-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  span {
    .cell-name {
      font-weight: bold;
      color: $navbar-background-color;
      size: small;
      .calendar-icon {
        display: inline;
        padding-left: 10px;
        padding-top: 10px;
      }
    }
  }
  .aum-total {
    color: $navbar-background-color;
  }
  .class-level {
    justify: center;
    .class-values {
      color: $navbar-background-color;
    }
  }
}
