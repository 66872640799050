.user-management-title {
  color: $navbar-background-color;
}
.user-toolbar {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-end;
  .MuiTypography-h4 {
    flex: 1;
  }
  .MuiButton-contained {
    margin-top: 15px;
    background-color: $body-background;
    color: $navbar-background-color;
  }
}

.user-table {
  .k-grid-header {
    background-color: $antarctica-color;
    color: white;
    .k-filter-row {
      .k-filtercell {
        background-color: white;
        border-radius: 10px;
      }
    }
  }
  .k-alt {
    background-color: $table-alter-row !important;
  }
  td,
  th {
    margin: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3em;
  }
}
