$body-background: #e4e4e4;
$body-color: #8e8e8e;
$navbar-background-color: #454545;
$navbar-color: #b0b0b0;
$antarctica-color: #0e4466;
$antarctica-light-color: #1b5c86;
$antarctica-light-background: #ced9e0;
$table-alter-row: #f0f4f7;
$error-color: #ff7161;
$warning-color: #ffbf00;
$info-color: #7ec9b5;
$sky-blue: #5e94c7;
$ice-blue: #b8d6eb;
$storm-blue: #427db8;
$ocean-navy: #0a2839;
$red-accent: #FF4D00;
$sapphire: #3069ab;
$highlight-blue: #f1f7fb;
$eggshell: #82B2D9;

// $media-extra-large: 1920px;
$media-large: 1745px;
$media-medium: 1279px;
$media-small: 959px;
$media-extra-small: 559px;

@mixin chart-shadow() {
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
  margin: 20px 0;
}
