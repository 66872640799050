.bulk-upload-route {
  .setup-container {
    display: flex;
    margin: 16px 0;
    .document-drop {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 0 16px;
    }
    .global-selectors {
      flex: 1;
      margin: 0 16px;
    }
  }

  .document-table-container {
    padding: 24px;
    .submit-row {
      margin: 24px 0;
      display: flex;
      justify-content: center;
    }
    .document-row {
      display: flex;

      & > *:nth-child(1) {
        flex: 1 0 0;
      }

      & > *:nth-child(2) {
        flex: 1 0 0;
      }

      & > *:nth-child(3) {
        flex: 1 0 0;
      }

      & > *:nth-child(4) {
        flex: 0 0 150px;
      }
    }
  }
  // .k-searchbar {
  //   padding: 0;
  // }
  // .start-over-btn {
  //   margin-left: 20px;
  // }
}
