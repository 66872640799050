.portfolio-fund-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  .weight-row {
    width: 100%;
    display: flex;
    margin: 8px 0;
    align-items: center;
    .global-search {
      flex: 1;
    }
    .k-numerictextbox {
      margin-left: 10px;
      width: 96px;
    }
    .MuiButtonBase-root {
      padding: 0;
      margin-left: 8px;
    }
    &:first-of-type {
      margin: 0;
      margin-bottom: 8px;
    }
    &:last-of-type {
      margin: 0;
      margin-top: 8px;
    }
  }
}
