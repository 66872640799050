.user-profile {
  .user-info {
    max-width: 500px;
    margin: 0 auto;
    form {
      display: flex;
      flex-direction: column;
    }
  }
  .security {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .new-device-btn {
      margin: 16px 0;
    }
    .qr-code {
      align-self: center;
      margin: 16px 0;
    }
    .delete-device-form,
    .token-list {
      display: flex;
      flex-direction: column;
    }
  }
}
