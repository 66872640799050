.manager-holdings-route {
  max-width: 90vw;
  margin: 8px auto;
  .holdings-table {
    .k-toolbar {
      overflow: visible;
    }
    .toolbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .filters-col {
        flex: 1;
        gap: 16px;
        display: flex;
        flex-direction: column;
        .filter-row {
          display: flex;
          margin: 0 15px;
          flex: 1;
          justify-content: space-between;
          align-items: center;
          .pivot-selectors {
            .k-textbox {
              flex: 1;
              margin-right: 16px;
              width: 272px;
            }
          }
        }
      }
      .fund-selector {
        flex: 1 1 750px;
      }

      .date-selector {
        margin-left: 8px;
        flex: 0 1 350px;
      }
    }
  }
  .k-grid-header-sticky,
  .k-grid-header-sticky:hover {
    background-color: $ocean-navy !important;

    span {
      color: white !important;
    }
  }
  .k-grid-content {
    td {
      &:first-of-type {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
