.notification-page {
  --border-color: var(--mantine-color-gray-2);

  .notification-actions {
    border-bottom: 1px solid var(--border-color);
    padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
    height: 50px;
  }

  .notification-list {
    border: 1px solid var(--border-color);
    position: relative;
    
    &-item {
      border-bottom: 1px solid var(--border-color);
      display: flex;
      padding: 0 var(--mantine-spacing-md);
      align-items: center;
      
      &-link {
        text-decoration: none; 
        color: unset;
        width: 100%;
        flex-wrap: nowrap;
        padding: var(--mantine-spacing-xs) 0;
      }

      &:last-child {
        border: 0;
      }

      &:hover {
        background-color: var(--mantine-color-highlightBlue);
      }
      
      &-dismiss-btn,
      &-download-btn {
        display: none !important;
      }

      &-time {
        display: block;
      }

      &:hover {
        .notification-list-item-time {
          display: none;
        }
        
        .notification-list-item-dismiss-btn {
          display: block !important;
          position: absolute;
          right: var(--mantine-spacing-md);
        }

        .notification-list-item-download-btn {
          display: block !important;
          position: absolute;
          right: calc(var(--mantine-spacing-md) * 3);
        }
      }
    }
  }
}

