.rich-text-editor-container {
  // display: block;
  // min-height: 500px;
  background: white;
  margin-bottom: 150px;
}
.tox-fullscreen {
  z-index: 1300 !important;
}

.tox-tinymce-aux {
  z-index: 1301 !important;
}

.tox-tiered-menu {
  z-index: 1305 !important;
}
