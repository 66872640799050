.lt-analytics-container {
  // display: grid;
  // grid-template-columns: repeat(6, 1fr);
  // grid-template-rows: repeat(2, 414px);
  // gap: 0 10px;
  .card-title {
    display: flex;
    justify-content: space-between;
    .MuiTypography-root {
      color: $navbar-background-color;
      padding: 10px;
    }
  }

  .plain-table-container {
    max-height: 268px;
    margin-top: 12px;
    .button-group {
      background-color: $antarctica-light-color;
      color: $antarctica-light-background;

      &:hover {
        background-color: $antarctica-color;
      }
    }
    .btn-cell {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
}
