.group-container {
  grid-template: 50px auto / [select-start] 1fr [select-end grid-start] 3fr [grid-end];
  gap: 20px;
  .tab-menu {
    grid-column: select-start/grid-end;
  }

  .groups {
    width: 400px;
  }

  .group-list-container {
    background: white;

    .MuiListItem-container {
      padding: 0 20px;
      &:hover {
        background: $highlight-blue !important;
      }
      .MuiListItem-root {
        border-bottom: 1px solid $ice-blue;
        &:hover {
          background: none !important;
        }
      }

      .MuiButtonBase-root {
        color: $sapphire;
      }
    }

    .new-group-form {
      display: flex;
      align-items: center;
      .MuiButtonBase-root {
        margin-left: 24px;
      }

      .MuiTextField-root {
        max-width: 400px;
        width: 100%;
      }
    }
    .MuiList-root {
      border: 1px solid $ice-blue;
      height: calc(100vh - 320px);
      overflow-y: scroll;
      padding: 0;
      margin-top: 14px;

      .selected-group {
        background: $body-color;
      }
      .MuiListItem-root {
        &:hover {
          background: $navbar-color;
        }
      }
    }
  }

  .tables-container {
    grid-column: select-start/select-end;
  }
  .group-manager {
    grid-column: grid-start/grid-end;
    .multi-select-row {
      display: flex;
      .MuiButtonBase-root {
        min-width: 250px;
        margin-left: 20px;
      }
    }
    .switch-control {
      display: flex;
      justify-content: center;
    }
  }
}
