.statistics-container {
  overflow: hidden;
  padding: 5px;
  .performance-input {
    max-width: 500px;
    margin: 32px auto;
  }
  .selectors {
    display: flex;
    flex-direction: column;
    border-style: double;
    border-color: $antarctica-color;
    border-radius: 10px;
    margin-bottom: 20px;
    .selectors-heading {
      margin: 0 15px;
    }
    .selectors-container {
      display: grid;
      grid-template-columns: 2fr 2fr 250px 2fr;
      .selector-column {
        margin: 0 15px;
        display: flex;
        flex-direction: column;
        .date-input-row {
          margin: 22px 0px;
          display: flex;
          .date-input {
            // margin: 0 10px;
            flex: 1;
          }
        }
      }
    }
  }
  .button-row {
    margin: 15px 15px;
  }
  .analysis {
    margin: 25px 0;
  }
  .downside-description {
    text-align: justify;
  }
}
