.user-form {
  .MuiPaper-root {
    background-color: white;
  }
  .app-bar {
    position: relative;
    color: #000000;
    .tool-bar {
      display: flex;
      justify-items: center;
      background-color: #ffffff;
      .toolbar-element {
        margin-left: 1rem;
        // margin: 1rem;
      }
    }
  }
  .user-container {
    margin: 1rem;
    .k-multiselect {
      background-color: $body-background;
    }
  }
}
