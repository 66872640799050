.gross-to-net-route {
  .selectors-container {
    text-align: center;
  }
  .output-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .table-container {
      max-width: 350px;
      .k-grid-header {
        tr {
          background-color: $antarctica-color;
          color: white;
        }
      }
    }
    form {
      display: flex;
      flex-direction: column;
      .form-button {
        display: flex;
        justify-content: center;
      }
      .excel-export {
        display: flex;
        justify-content: center;
      }
      & > * {
        margin: 8px 0;
        width: 280px;
      }
    }
  }
}
