.rms-grid {
  font-size: medium;
  .k-toolbar {
    overflow: visible;
    .toolbar {
      display: flex;
      align-items: center;
      & > span {
        margin: 0 8px;
      }
      .global-search {
        width: 350px !important;
      }

      .add-new-button {
        margin-left: auto;
        color: white;
        white-space: pre;
        padding: 0 24px;
      }
    }
  }
  .k-grid-header {
    background-color: $antarctica-color;
    font-size: 15px;
    color: white;
    padding-right: 0;
    $hovered-text: grey;
  }

  tr {
    font-size: small;
  }
  td {
    padding: 0.2rem 0.6rem;
  }
  thead {
    th {
      padding: 0.4rem 0.6rem;
      &:hover {
        background-color: $antarctica-light-color;
        & * {
          color: white !important;
        }
      }
    }
    .k-sorted {
      background-color: $antarctica-light-color;
      & * {
        color: white !important;
      }
    }
  }
  .button-group {
    background-color: $antarctica-light-color;
    color: $antarctica-light-background;
    &:hover {
      background-color: $antarctica-color;
    }
  }
}
