.root {
  background: var(--mantine-color-sapphire);
  padding: 0;
  cursor: pointer !important;

  &[data-size="xl"] {
    label {
      font-weight: 400;
      font-size: 14px;
      padding-top: 12px;
      padding-bottom: 12px;
      cursor: pointer;
    }
  }
}

.control {
  --label-bg-color: white;

  label {
    color: var(--label-bg-color);
    text-transform: uppercase;
    font-weight: bold;
    padding: 8px 16px;

    &:hover {
      color: var(--label-bg-color);
    }
  }
}

.indicator {
  background: var(--mantine-color-oceanNavy);
}
