.root {
  border-radius: 0;
  height: 48px;
  background-color: var(--mantine-color-sapphire);
  color: white;
  text-transform: uppercase;
  font-size: 13px;
  transition: 225ms ease;

  &:hover {
    background-color: var(--mantine-color-oceanNavy);
    transition: 225ms ease;
  }

  &[data-variant='outline'] {
    color: var(--mantine-color-sapphire);
    background: white;
    border: 1px solid var(--mantine-color-sapphire);

    &:hover {
      background-color: #f3f3f3;
    }
  }

  &[data-variant="transparent"] {
    background-color: transparent;
    text-transform: none;
    color: var(--mantine-color-oceanNavy);
  }
  
  &:disabled {
    background-color: var(--mantine-color-iceBlue);
    color: white;
    opacity: 0.5;
  }
}