.transfer-list {
  margin: auto;
  .search-box {
    padding: 0.5rem;
    background-color: $antarctica-color;

    input {
      color: white;
    }
  }
  .MuiPaper-root {
    width: 100%;
    height: 300px;
    overflow: auto;
  }
  .transfer-button {
    margin: 0.5rem;
  }
}
