.portfolio-builder-container {
  margin: 0 auto;
  max-width: 800px;
  .card-header {
    padding: 1rem;
  }
  .stepper-container {
    .description-container {
      .k-textbox,
      .kendo-virtual-combobox {
      }
    }

    .weight-row {
      display: flex;
      .MuiIconButton-root {
        padding: 0;
      }
      .weight-input {
        width: 96px;
      }
    }
    .total {
      justify-content: space-between;
      align-items: center;
    }
    .fees-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 16px 0;
      .k-textbox-container {
        flex: 0 1 150px;
      }
    }
    .meta-row {
      display: flex;
      align-items: center;
      margin: 16px 0;
    }
    .rebalancing-dropdown {
      flex: 0 1 250px;
      margin-right: 32px;
    }
    // .portfolio-textfields {
    //   padding-left: 1rem;
    //   padding-bottom: 2rem;
    // }
    // .weight-row {
    //   padding-left: 1rem;
    //   flex-direction: inline-block;
    //   .total-weight {
    //     float: right;
    //     padding-top: 0.9rem;
    //     padding-right: 1rem;
    //     color: $navbar-background-color;
    //   }
    //   .button-group {
    //     padding-top: 2rem;
    //     padding-left: 1rem;
    //     padding-bottom: 2rem;
    //   }
    // }
    // .portfolio-benchmarks {
    //   .portfolio-actions {
    //     padding-top: 2rem;
    //   }
    // }
    // .portfolio-benchmarks {
    //   display: flex;
    //   flex-direction: column;
    //   padding-left: 1rem;
    //   .date-selector {
    //     padding-top: 1rem;
    //     padding-bottom: 1rem;
    //   }
    //   .meta-text-fields {
    //     display: flex;
    //     flex-direction: column;
    //     .text-input {
    //       -webkit-appearance: none;
    //       & input {
    //         padding: 10px 5px;
    //         &::-webkit-inner-spin-button {
    //           -webkit-appearance: none;
    //           margin: 0;
    //         }
    //         &::-webkit-outer-spin-button {
    //           -webkit-appearance: none;
    //           margin: 0;
    //         }
    //       }
    //     }
    //   }
    //   .date-picker-row {
    //     margin: 20px 5px;
    //     display: flex;
    //     align-items: center;
    //     .date-picker {
    //       margin: 0 10px;
    //     }
    //   }
    //   .portfolio-switches {
    //     display: flex;
    //   }
    //   .portfolio-actions {
    //     .action-button {
    //       float: right;
    //       color: white;
    //       background-color: $antarctica-color;
    //     }
    //   }
    // }
  }

  .control-container {
    display: flex;
    justify-content: space-between;
  }
}
