.activity-report {
  .selectors {
    margin: 8px 0;
    display: flex;
    justify-content: center;
    .selector-form {
      display: flex;
      flex-direction: column;
      .item {
        margin: 2px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .page-views {
    max-width: 800px;
    margin: 0 auto;
    .MuiAccordionSummary-content {
      justify-content: space-between;
    }
    .MuiCollapse-container {
      background-color: white;
    }
  }
}
