.slider-filter-with-inputs {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  .filter-name {
    width: 30%;
  }
  .filter-component {
    display: flex;
    align-items: center;
    flex: 1;
    .slider {
      width: 100%;
    }

    .slider-input {
      margin-left: 10px;
      margin-right: 10px;
      width: 130px;
      -webkit-appearance: none;
      & input {
        padding: 10px 5px;
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }
}
