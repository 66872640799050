.kendo-performance-grid-container {
  .performance-grid {
    .k-grid-header {
      background-color: $antarctica-color;
      color: white;
    }
    .k-grid-container {
      td {
        &:nth-of-type(2) {
          padding: 0;
          span {
            width: 100%;
          }
        }
      }
    }
  }
}
