.report-selector-form {
  max-width: 1280px;
  min-height: 250px;
  background-color: white;
  // color: white;
  padding: 30px 20px;
  margin: 50px auto;
  display: grid;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  grid-template:
    none / auto [content-start] 700px [content-end helper-start] 30px
    [helper-end] auto;
  grid-auto-rows: minmax(60px, auto);
  align-items: center;
  grid-auto-flow: row dense;
  gap: 0 10px;
  border-radius: 5px;
  .k-input {
    // color: white;
  }
  .single-selector {
    grid-column: content-start/content-end;
    .dropzone {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: #eeeeee;
      border-style: dashed;
      background-color: #fafafa;
      color: #bdbdbd;
      outline: none;
      transition: border 0.24s ease-in-out;
      margin-top: 20px;
    }
  }
  .one-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .k-datepicker {
      &:first-of-type {
        margin-right: 5px;
      }
      &:last-of-type {
        margin-left: 5px;
      }
    }
  }
  .selector-explanation {
    grid-column: helper-start/helper-end;
    .help-icon {
      color: $navbar-color;
    }
  }
  .generate-button {
    // background-color: $antarctica-color;
    border-radius: 5px;
    grid-column: content-start/content-end;
    display: flex;
    flex-direction: column;
    align-items: right;
    .MuiButton-label {
      color: white;
    }
  }
}

.report-selectors-container {
  .generate-button {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: right;
    .MuiButton-label {
      color: white;
    }
  }
  .single-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
    // max-height: 250px;
    // overflow-y: scroll;
    .dropzone {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: #eeeeee;
      border-style: dashed;
      background-color: #fafafa;
      color: #bdbdbd;
      outline: none;
      transition: border 0.24s ease-in-out;
      margin-top: 20px;
    }
  }
}
