.monitor-route {
  max-width: 1280px;
  margin: 0 auto;
  .index-monitor {
    .generic-table-container {
      .k-toolbar {
        min-height: 60px;
      }
      .toolbar {
        display: flex;
        align-items: center;
      }
      td {
        &:nth-of-type(2) {
          padding-left: 24px;
          text-align: left !important;
        }
      }
    }
  }
}

.exposure-monitor {
  max-width: 93vw;
  margin: 0 auto;
  .k-toolbar {
    overflow: visible;
    .toolbar {
      display: flex;
      align-items: center;
      .kendo-virtual-combobox {
        width: 250px !important;
        margin-right: 24px;
      }
    }
  }

  table {
    padding-bottom: 16px;
    padding-right: 16px;
  }
}
