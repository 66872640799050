.chart-container {
  .chart-header {
    background-color: white;
    color: $navbar-background-color;
    text-align: center;
    padding-top: 10px;
  }
  //   .chart-background {
  //     color: red;
  //   }
}
