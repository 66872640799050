.screener-container {
  .clear-selection-button {
    align-items: right;
    color: $navbar-background-color;
    font-weight: bold;
  }

  .screener-table {
    font-size: medium;
    border: unset;
    .k-toolbar {
      min-height: 100px;
    }
    .k-grid-container {
      min-height: 100%;
    }
    .toolbar {
      display: flex;
      align-items: center;

      .filter-button {
        margin-left: 20px;
        font-weight: bold;
      }

      .search-bar {
        flex: 1 1;
        margin: 0 20px;
        height: 48px;
        border-radius: 0;
        border-color: $sky-blue;
        padding-left: 16px;
        padding-right: 16px;

        &::placeholder {
          color: $sky-blue;
        }
      }
    }
    .filter-toolbar {
      display: flex;
      margin: 0;
      .filter-container {
        width: 160px;
        margin: 8px;
        &:first-of-type {
          margin-left: 0 !important;
        }
        .filter-active {
          .k-dropdown-wrap {
            background: $sapphire;
            color: white;
          }
        }
      }
    }
    .k-grid-header {
      background-color: $antarctica-color;
      font-size: 15px;
      color: white;
      $hovered-text: grey;
    }
    tr {
      font-size: small;
      border: 1px solid $eggshell;
    }
    td {
      padding: 0;
      text-align: center;
      & :nth-of-type(1) {
        text-align: left;
      }
      & :nth-of-type(2) {
        text-align: left;
      }
    }

    thead {
      th {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        text-align: center;
        &:hover {
          background-color: $antarctica-light-color;
          & * {
            color: white !important;
          }
        }
        &:nth-of-type(2) {
          text-align: left;
          padding-left: 16px;
        }
      }
      .k-sorted {
        background-color: $antarctica-light-color;
        & * {
          color: white !important;
        }
      }
    }

    .cell-name {
      cursor: pointer !important;
      width: 400px;
      .aaap-name {
        color: $antarctica-color;
        padding: 0;
        padding-left: 16px;
      }
      .underlying-name {
        padding: 0;
        padding-left: 16px;
      }

      &:hover {
        .aaap-name {
          text-decoration: underline;
        }
      }
    }
  }

  .k-grid-content {
    overflow-x: hidden !important;
    border-right: 1px solid $eggshell;
  }
}
