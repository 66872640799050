@import '../../hubspot-rich-text.style';
.asset-detail-container {
  display: flex;
  flex-direction: column;
  .control-row {
    display: flex;
    margin: 20px 0;
    .save-button {
      margin-right: auto;
    }
  }
  .summary {
    background-color: blue;
    border-radius: 5px;
    color: $navbar-background-color;
    .MuiTypography-root {
      color: $navbar-background-color;
      padding-bottom: 10px;
    }
    margin-bottom: 10px;
    white-space: pre-wrap;
  }

  .table-container {
    .MuiTypography-root {
      padding: 10px;
      color: $navbar-background-color;
      justify-content: space-between;
    }
    margin-top: 5px;
    background-color: white;
    .MuiPaper-root {
      padding: 0 300px;
    }
    .data-type {
      font-weight: bold;
      color: $antarctica-color;
    }
  }
}


.fund-detail-hs-summary {
  @include hubspot-rich-text-styles;
}