.classification-table {
  background-color: white;
  // @include chart-shadow;
  padding: 10px 30px;
  .MuiTable-root {
    margin-top: 40px;
    td {
      &:first-of-type {
        font-weight: bold;
        color: grey;
      }
      &:last-of-type {
        color: $antarctica-color;
        font-weight: bolder;
      }
    }
  }
}
