.asset-classification-chart-container {
  // margin: 20px 0;
  display: flex;
  .classification-table {
    flex: 2;
    // min-height: 500px;
    padding-top: 10px;
    padding-right: 5px;
    border-radius: 5px;
    padding-left: 5px;
    .MuiTypography-root {
      color: $navbar-background-color;
    }

    // margin-right: 5px;
    // margin-bottom: 10px;
  }
}

.line-ts-chart-container {
  flex: 7;
  padding: 10px 0;
  // padding-bottom: 20px;
  padding-top: 20px;
  // margin-left: 5px;
  padding-left: 5px;
  border-radius: 5px;
  border: 1px solid $body-background;
  .title-container {
    color: $navbar-background-color;
  }
}
