.tab {
  margin-bottom: 12px;
  background-color: var(--mantine-color-sapphire);
  border: 1px solid var(--mantine-color-sapphire);
  color: white;
  text-transform: uppercase;
  height: 48px;
  font-size: 14px;
  font-weight: bol;
  padding: 10px 20px;

 &[data-active] {
  z-index: 1;
  background-color: var(--mantine-color-oceanNavy);
  border-color: var(--mantine-color-oceanNavy);
  color: white
 }
}

.list {
  &:before {
    display: none;
  }
}