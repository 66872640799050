.data-management-route {
  margin: 0 auto;
  .security-setup-container {
    .title {
      display: flex;
      justify-content: center;
      position: relative;
      .set-up-mode-button {
        position: absolute;
        right: 0;
      }
    }
    .submit-button {
      margin: 8px auto;
    }
    .date-picker-row {
      display: flex;
      align-items: center;
      padding: 0 4px;
      .k-datepicker {
        margin-left: auto;
        width: 50%;
      }
    }
    .new-fund-form {
      & > * {
        margin: 4px 0;
      }
      .new-security-submit {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
    max-width: 500px;
    margin: 32px auto;
    .single-selector {
      margin: 16px 0;
    }

    .generate-button {
      display: flex;
      flex-direction: column;
    }
  }
}
