.insights-route {
  margin: 0 auto;
  max-width: 1100px;
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr 1fr 1fr;
  overflow: hidden;
  grid-gap: 24px;
  color: #4d5156;
  margin-top: 20px;

  .MuiCard-root {
    border: none !important;
  }
}

@media (max-width: $media-medium) {
  .insights-route {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: $media-small) {
  .insights-route {
    grid-template-columns: 1fr;
  }
}
