@mixin hubspot-rich-text-styles {
  span {
    font-family: "Gotham A", "Gotham B", sans-serif !important;
    font-size: 16px !important;
  }

  & > * {
    display: block;
    box-sizing: border-box;
  }

  & img {
    float: unset;
    margin: 0;
    max-width: 100%;
    height: auto;
  }

  p {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  ul {
    padding-left: 36px !important;
  }

  li {
    font-size: 16px !important;
    margin: 16px 0;

    span {
      font-size: 16px !important;
    }
  }

  table {
    border: 1px solid $sapphire !important;
    border-collapse: collapse !important;
    height: unset !important;
    width: 100% !important;

    tr,
    td {
      height: unset !important;
    }

    td {
      border: 1px solid $sapphire !important;

      p {
        padding: 12px 20px !important;
      }
    }
  }
}