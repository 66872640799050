.container-page {
  .toolbar {
    background-color: white;
    margin-bottom: 12px;
    .date-picker {
      margin-top: 10px;
    }
    .chart-buttons {
      float: right;
    }
  }
}
