.generic-table-container {
  margin: 20px 0;
  padding: 0;
  .k-grid,
  .generic-table {
    .k-link,
    .k-grid-header {
      color: unset;
      cursor: unset;
      &:hover {
        color: unset;
      }
    }
    tr {
      background-color: unset;
      &:hover {
        background-color: unset;
      }
    }
    td,
    th {
      padding: 3px 0.5px;
      text-align: center;
      border-width: 0.5px 0.5px;
      border-color: #ddd;
      color: $navbar-background-color;
      &:first-of-type {
        text-align: left;
        font-weight: bold;
        padding-left: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 250px;
      }
      &:hover {
        background-color: white;
      }
    }
    th {
      background-color: $antarctica-color;
      color: white;
      border: 1px solid #ddd;
      &:hover {
        background-color: $antarctica-color;
        color: white !important;
      }
    }

    &.alt-color {
      .k-alt {
        background-color: $table-alter-row;
        &:hover {
          background-color: $table-alter-row;
        }
        td {
          &:hover {
            background-color: $table-alter-row;
          }
        }
      }
    }

    .aggregate-row {
      td {
        font-weight: bold;
        background-color: $body-background;
      }
    }

    .no-alt-color {
      .k-alt {
        background-color: white;
        &:hover {
          background-color: white;
        }
        td {
          &:hover {
            background-color: white;
          }
        }
      }
    }
  }

  // .k-grid .k-grid-header .k-header {
  //   height: auto;
  //   white-space: normal;
  //   vertical-align: middle !important;
  // }
  .total-row {
    tr:last-of-type {
      font-weight: bold;
      background-color: #adadad;
      &:hover {
        background-color: #adadad;
      }
    }
  }
  .total-col {
    tr {
      td:last-of-type {
        font-weight: bold;
        background-color: #adadad;
      }
    }
  }
  .summary-years {
    tr {
      td:nth-last-of-type(-n + 4) {
        font-weight: bold;
        background-color: #cacaca;
      }
    }
  }
  .blue-heading {
    thead {
      th {
        background-color: $antarctica-light-color;
        color: white;
        &:hover {
          background-color: $antarctica-light-color;
          color: white;
        }
      }
    }
  }
  .highlight-name {
    thead {
      th:first-of-type {
        background-color: #686868;
        color: white;
        &:hover {
          background-color: #686868;
          color: white;
        }
      }
    }
  }
}
