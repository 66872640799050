

@import "base/base";
@import "routes/routes";
@import "containers/containers";
@import "components/components";
@import "./hubspot-rich-text.style";

body {
  margin: 0;
  height: 100%;
}

html,
body {
  height: 100%;
  font-family: Roboto, sans-serif;
}

* {
  font-family: "Gotham A", "Gotham B", sans-serif;
  box-sizing: border-box;
}

#root {
  background-color: #edeff0;
  // height: 100%;
  min-height: 100%;
}

.k-grid-toolbar {
  flex-shrink: 0;
  border: none;
}

.k-tooltip {
  background-color: white;
  color: black;

  .tooltip-row {
    display: flex;
    margin: 10px 0;

    .row-name {
      color: $antarctica-color;
      font-weight: bold !important;
    }
  }
}

.k-list-container {
  z-index: 11000 !important;
}

.chart-major-line {
  background-color: brown !important;
}

.negative-red {
  color: #ff0040 !important;
}

.k-animation-container {
  z-index: 999;
  box-shadow: none;

  .k-popup {
    background: white;
    border-color: $sky-blue;
    border-top: none;
    box-shadow: none;

    ul {
      li {
        color: $ocean-navy !important;
        padding-left: 16px;
        font-size: 16px;
      }
    }

    .k-item:hover {
      background-color: $highlight-blue;
      color: $ocean-navy;
    }
  }
}

.delete-story-button {
  background: $error-color !important;
}

.MuiTabs-root {
  width: 100%;
}

.dashboard-tabs {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .MuiTabs-root {
    width: 75%;
    margin: 0 !important;
  }

  .demo-button {
    background-color: $red-accent;
    border-radius: 0;
    color: white;
    font-size: 16px;
    height: 48px;
    text-align: center;
    text-transform: none !important;
    width: 217px;
  }
}

.k-grid-header {
  background: $ice-blue !important;
  color: $ocean-navy !important;
  padding-right: 0 !important;
}

.k-alt,
.k-alt:hover {
  background: $highlight-blue !important;
}

.compare-buttons {
  .compare-btn {
    border: none !important;
  }

  button,
  span,
  a {
    color: white;
  }
}

.global-search {
  min-height: 48px;
  font-size: 16px;

  input {
    height: 100%;
    padding: 13px 15px;
  }
}

.k-multiselect-wrap {
  border-radius: 0 !important;
  min-height: 48px;
  height: 100%;
  font-size: 16px;
  border: 1px solid $sky-blue !important;
  padding: 13px 15px !important;
  display: inline-flex;
  align-items: center;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  & .k-input {
    padding: 0;
  }
}

.k-textbox {
  min-height: 48px;
  height: 100%;
  font-size: 16px;
  border: 1px solid $sky-blue !important;
  padding: 13px 15px !important;
  display: inline-flex;
  align-items: center;

  & .k-input {
    padding: 0;
  }

  &:focus,
  &:active,
  &:hover {
    border: 1px solid $sky-blue;
    box-shadow: none;
  }

  &::placeholder {
    color: $sky-blue;
  }
}

.k-state-focused {
  box-shadow: none !important;
}

.k-clear-value,
.k-dropdown-wrap .k-i-close {
  top: 12px !important;
}

.k-combobox,
.k-dropdown {
  border: 1px solid $sky-blue;
  background: white;
  min-height: 48px;

  .k-dropdown-wrap {
    background: white !important;
  }

  .k-state-disabled {
    background: #f3f3f3 !important;
    border-radius: 0;
  }

  .k-input {
    height: 100%;
    color: $sky-blue;
    padding-left: 15px;
    font-size: 16px;
  }
}

.k-header {
  background: $ice-blue !important;
  color: $ocean-navy !important;
  font-weight: bold !important;

  &:hover {
    * {
      color: $ocean-navy !important;
    }
  }
}

.k-master-row {
  td:hover {
    background-color: $eggshell !important;
  }
}

.k-alt {
  background-color: $highlight-blue !important;
}

.k-grid-content {
  overflow-y: overlay !important;
  overflow-x: auto !important;
  border-bottom: 1px solid $ice-blue;
}

.k-widget {
  border-radius: 0 !important;

  & input::placeholder {
    color: $sky-blue !important;
  }
}

.k-select {
  background: none !important;
}

.k-alt {
  .k-grid-content-sticky {
    background-color: $ice-blue !important;
  }
}

.k-header {
  border-color: $eggshell !important;
}

.k-grid-table {
  max-width: calc(100% - 1px) !important;

  td {
    border-color: $eggshell !important;
    color: $ocean-navy;
  }
}

.k-grid {
  border: none;

  table {
    max-width: calc(100% - 1px);
  }
}

.k-grid tbody tr:hover {
  background: $ice-blue !important;
}

table {
  border-collapse: collapse !important;
}

.custom-select-input {
  width: 100%;
}

.icon-button {
  color: $sapphire;
}

.toolbar-menu {
  .MuiListItem-root:hover {
    background-color: $highlight-blue !important;

  }

  .MuiListItemIcon-root {
    color: $ocean-navy;
  }

  .MuiTypography-root {
    color: $ocean-navy !important;
  }
}

.filter-pane {
  input {
    padding: 0 !important;
  }

  .MuiInputAdornment-positionStart {
    margin-right: 0 !important;
  }
}

.mobile-drawer {
  .MuiPaper-root {
    background: $ocean-navy;
  }

  * {
    color: white;
  }

  hr {
    background: $ice-blue;
  }
}

.back-to-login-btn {
  display: flex;
  justify-content: flex-start;
  color: white;
  font-size: 16px;
  text-transform: none;
  text-decoration: underline;
}

.doc-copy-button {
  color: $sapphire;
}

.copy-snackbar-wrapper {
  background: $sapphire;
  color: white;
  padding: 16px 24px;
}

.highlights-pane {
  background: white;
  border: 1px solid $ice-blue;
  height: 100%;

  .highlights-header-wrapper {
    border-bottom: 1px solid $ice-blue;
  }

  .highlights-link-text {
    font-weight: bold;
    color: $sapphire;
  }

  .highlights-items-wrapper {
    overflow-y: auto;
    max-height: 460px;

    .highlights-item {
      text-decoration: none;
      color: $ocean-navy;

      &:hover {
        background: $highlight-blue;
      }

      &:visited {
        color: $ocean-navy;
      }

      &:not(:first-child) {
        border-top: 1px solid $ice-blue;
        color: $ocean-navy;
      }
    }
  }
}

.insights-content {
  background: $highlight-blue;
  max-width: 1200px;
  margin: 0 auto;

  span {
    font-family: "Gotham A", "Gotham B", sans-serif !important;
    font-size: 16px !important;
  }

  &-header {
    font-weight: bold;
    margin-bottom: 20px;
  }

  &-publish-date {
    color: gray;
    margin-bottom: 40px;
    display: block;
  }

  &-inner-wrapper {
    @include hubspot-rich-text-styles;
    padding: 20px 24px;

    @media (min-width: $media-extra-small) {
      padding: 40px;
    }

    @media (min-width: $media-small) {
      padding: 40px 102px;
    }
  }
}

.recent-insights-wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 16px;
  max-width: 1200px;
  margin: 20px auto;
}

.recent-insights-header {
  font-weight: bold;
  max-width: 1200px;
  margin: 60px auto 20px;
  color: $sapphire;
}

.insights-content-wrapper {
  width: 100%;
  border: 1px solid $ice-blue;
  height: calc(100% - 200px);
  padding: 0 20px;
}

.insights-card-link {
  text-decoration: none;
}

.insights-card-link-homepage {
  display: block;
  margin-bottom: 12px;

  .MuiCard-root {
    border: none;
  }

  .insights-piece {
    display: flex;
    align-items: flex-start;
    height: unset;

    .card-title {
      font-size: 16px;
    }

    .card-image {
      height: auto;

      img {
        height: 130px;
        width: 130px;
      }
    }

    .insights-content-wrapper {
      border: none;
    }

    .card-description {
      padding: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.insights-piece {
  background-color: white;
  height: 450px;
  overflow: hidden;

  &>* {
    text-decoration: none;
  }

  .card-image {
    pointer-events: none;
    height: 200px;

    img {
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
  }

  .card-title {
    margin-top: 4px;
    margin-bottom: 12px;
    font-size: 24px;
  }

  a {
    text-decoration: none;
    // color: #4d5156;
    color: black;
  }

  a:hover {
    color: #1a0dab;
  }

  .card-date {
    text-align: left;
    padding: 10px 0 0 0;
    font-size: 90%;
    margin: 0;
  }

  p {
    padding: 0 20px;
  }

  .card-description {
    margin-top: 0;
    padding: 0;
  }
}

.dashboard-card {
  height: 100%;
}

.MuiToggleButtonGroup-root {
  border-radius: 0;

  .MuiButtonBase-root {
    border-radius: 0;
    padding: 10px 20px;
    font-weight: bold;
    border: 1px solid $sapphire;
    color: $sapphire;
    white-space: nowrap;

    &:hover {
      background: none;
    }
  }

  .Mui-selected {
    background: $sapphire;
    color: white;
    pointer-events: none;
    ;

    &:hover {
      background: $sapphire;
    }
  }

  .Mui-disabled {
    opacity: 0.2;
  }
}

.MuiSelect-select:focus {
  background: white !important;
}

.MuiAutocomplete-paper {
  border-radius: 0;
}

.MuiAutocomplete-listbox {
  border-radius: 0;

  & *[data-focus="true"] {
    background-color: $highlight-blue !important;
  }
}

.MuiAutocomplete-option {
  color: $ocean-navy;

  &:hover {
    background: $highlight-blue;
  }

  &:focus {
    background: $highlight-blue;
  }


}

.MuiAutocomplete-option[data-focus="true"] {
  background-color: $highlight-blue !important;
}

.landing-page-wrapper {
  margin: 0 auto;

  .greeting-msg {
    text-align: center;
    margin: 40px 0 32px;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;

    .link-button {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-decoration: none;
      height: 500px;
      transition: 225ms ease;
      max-width: 800px;
      width: 100%;

      &--disabled {
        background: lightgray !important;
        opacity: 1;
        border: none !important;

        & span {
          color: gray !important;
        }
      }

      & img {
        width: 400px;
      }

      & span {
        font-weight: bold;
        font-size: 48px;
        margin-top: 24px;
        text-transform: uppercase;
        text-align: center;
      }

      &:first-child {
        border: 1px solid $sapphire;
        color: $ocean-navy;
      }

      &:last-child {
        background: $ocean-navy;
        color: $ice-blue;
      }

      &:hover {
        background: $sapphire;
        transition: 300ms ease;
        color: white;
      }
    }
  }

  .info-wrapper {
    display: flex;
    gap: 16px;
    margin-top: 20px;

    &>* {
      flex: 1 0 0;
    }

    @media (max-width: $media-small) {
      flex-direction: column;
    }
  }
}

.insights-card {
  border: 1px solid $ice-blue;
  background: white;
  height: 100%;

  &-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }

  .insights-items-wrapper {
    padding: 0 16px 16px;
    max-height: 520px;
    overflow-y: auto;
  }
}

.hf-insights-wrapper {
  &>* {
    height: 100%;
  }
}

.MuiDrawer-root {
  position: absolute;

  .MuiListItem-root {
    height: 48px;
  }

  .MuiListItemIcon-root {
    min-width: 42px;
  }

  .MuiPaper-root {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {
    background: transparent;
    height: 8px;
    width: 8px;
    // display: none;
  }

  ::-webkit-scrollbar-thumb {
    background: $ice-blue;
    opacity: 0.1;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    // display: none;
  }

  .MuiListItemText-root {
    transition: 200ms ease;
  }

  &:hover {
    .MuiPaper-root {
      overflow-y: overlay;
    }
  }

  .active-link {
    background: $sapphire;
  }
}

.MuiBreadcrumbs-li {
  a {
    text-decoration: none;
  }
}

.asset-detail-subheader {
  margin-bottom: 12px;
}

.k-state-selected {
  background-color: $eggshell !important;

  &:hover {
    background-color: $eggshell !important;
  }
}

.clickable-table-row {
  cursor: pointer;
}

.active-table-row {
  background-color: $eggshell !important;
}

.booklet-funds-selection-list {
  overflow-y: scroll;
  max-height: 250px;
  border: 1px solid #f3f3f3;
  border-right: none;
}

.custom-route-link {
  color: $ocean-navy;
  text-decoration: unset;

  &:hover {
    text-decoration: underline;
  }
}

.disabled-route-link {
  pointer-events: none;
  opacity: 0.5;
}

.user-guide-button {
  background-color: green;
}

.statistics-container {

  .k-combobox.k-state-invalid .k-dropdown-wrap,
  .k-combobox.ng-invalid.ng-touched .k-dropdown-wrap,
  .k-combobox.ng-invalid.ng-dirty .k-dropdown-wrap {
    border: none !important;
  }
}

.link-table-row {
  cursor: pointer;
}

.k-datepicker {
  height: 46px;
  border: 1px solid $sky-blue;

  .k-picker-wrap {
    border: none;
  }

  & input {
    height: 100%;
    color: $eggshell !important;
    font-size: 16px;
  }
}

.kendo-datepicker {
  width: 100%;
}

.trading-calendar-reasons-table {
  border: 1px solid gray;
  td, th {
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
    padding: 8px;
  }

  .multiple-reasons-date-row {
    td, th {
      padding-bottom: 8px;
      padding-top: 8px;
  }
    }
}

.logout-screen {
  position: fixed;
  background: #3069ab;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 100000;
}
.fund-detail {
  &-hs-summary {
    img {
      display: block;
      margin: 0 auto;
    }
  }
}

.mantine-TagsInput-inputField::placeholder,
.mantine-TextInput-input::placeholder {
  color: var(--mantine-color-skyBlue) !important; 
}

.mantine-TextInput-input[aria-invalid="true"]::placeholder {
  color: red !important;
}

.active-table-row {
  background-color: var(--mantine-color-skyBlue);
}

.clickable-row {
  cursor: pointer;
}

.mantine-Table-th > div {
  display: flex;
  align-items: center;
}

.selected-row {
  background: var(--mantine-color-iceBlue) !important;
}

.mantine-Combobox-search {
  padding-left: 15px !important;
}

.mantine-Table-th,
.mantine-Table-td {
  text-wrap: nowrap;
}

.highlighted-pill {
  background-color: var(--mantine-color-sapphire) !important;
  transition: 200ms ease;
}

.unhighlighted-pill {
  background-color: var(--mantine-color-highlightBlue) !important; 
  transition: 200ms ease;
}

.mantine-PillsInputField-field::placeholder {
  color: var(--mantine-color-skyBlue) !important;
}

.login-icon-button {
  background: none !important;
}

.filled-datatable {
  margin: 0 -16px;
}

.global-fund-search {
  .mantine-Combobox-option {
    padding: 0;
  }
}

.reset-password-input {
  input {
    text-transform: lowercase;
  }
}