.aaap-disclaimer {
  // padding: 1rem;
  border-radius: 5px;
  color: $navbar-background-color;
}

.performance-disclaimer {
  margin: 1rem 0;
  border-radius: 5px;
  color: $navbar-background-color;
}
