#portfolio-popover {
  .MuiPaper-root {
    padding: 0 15px;
  }
}
.basic-details {
  padding-top: 20px;
  padding-bottom: 20px;
  .asset-name-date {
    display: flex;
    align-items: center;
    color: $navbar-background-color;
    .download-button {
      align-self: center !important;
      padding: 0 !important;
      margin-left: 8px;
    }
    .status-date {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .strategy {
        font-weight: 300;
        font-size: 20px;
        margin-bottom: 8px;
      }

      .status {
        margin-left: auto;
        border-radius: 0;
        color: white;
        padding: 0px 24px;
        font-size: 16px;
        height: 48px;
      }
      .asset-date {
        display: flex;
        .calendar-icon {
          color: $navbar-background-color;
        }
      }
    }
  }
}
