.selector-group-name {
  color: $ocean-navy !important;
  font-weight: bold !important;
  cursor: default !important;
  &:hover {
    background-color: white !important;
    cursor: default !important;
  }
}

.nav-bar {
  display: flex;
  .menu-icon {
    color: white;
  }

  .MuiAppBar-positionFixed {
    background-color: #ffffff;
  }

  .MuiDrawer-paperAnchorLeft {
    background-color: $ocean-navy;
    color: white;

    .nav-divider {
      background-color: $eggshell;
    }

    .nav-divider:last-child {
      display: none;
    }
  }
  .page-header {
    padding: 8px 24px 8px 0;
    margin-bottom: 8px;

    .MuiTypography-root {
      color: $sapphire;
      font-size: 16px;
      font-weight: lighter;
    }
    .breadcrumb-link {
      cursor: pointer;
    }
  }

  .MuiAppBar-root {
    height: 60px;
  }

  .app-bar-toolbar {
    display: flex;
    // justify-content: space-between;

    .logo-link {
      display: flex;
      
      .logo {
        height: 34px;
        width: auto;
      }
    }

    .search-input-icon {
      background: white;
      position: absolute;
      right: 6px;
      top: 12px;
      font-size: 24px;
      color: $sky-blue;
    }

    .search-input {
      position: relative;
      width: 100%;
      height: 48px;
      max-width: 576px;

      .k-select {
        display: none !important;
      }

      & > * {
        margin: 0 !important;

        input {
          height: 46px;
          color: $sky-blue;
          padding: 0 40px 0 16px;

          &::placeholder {
            color: $sky-blue;
          }
        }

        .k-dropdown-wrap {
          border: none !important;
        }
      }
    }

    .antarctica-logo {
      height: 3rem;
      width: 7rem;
      cursor: pointer;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../assets/images/landscape_logo.svg");
    }
    .search-field {
      flex: 0 1 800px;
      margin: 0 8px;
      display: flex;
      align-items: center;

      background-color: $body-background;
      min-height: 40px;
      border-radius: 2px;
      padding: 0;
      .k-dropdown-wrap {
        height: 100%;
        background-color: $body-background;
        border: none;
      }
      .k-state-focused {
        height: 100%;
        background-color: unset;
        box-shadow: unset;
      }

      .k-select {
        display: none;
      }
    }
    .search-field-focused {
      border: 2px solid $antarctica-color;
    }
    .account-management-container {
      cursor: default;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #000000;

      .MuiAvatar-root {
        background-color: $ice-blue;
        height: 48px;
        width: 48px;
      }

      .account-management-control {
        display: flex;
        cursor: pointer;
        justify-content: flex-end;
        align-items: center;
        .user-name {
          padding: 0 12px 0 8px;
          color: $storm-blue;
        }

        .MuiAvatar-root {
          width: 36px;
          height: 36px;
          font-size: 16px
        }
      }
    }
  }
  .content {
    background-color: white;
    flex-grow: 1;
    padding: 76px 1rem 1rem 72px;
    padding-left: 72px;
    min-height: 100vh;
    z-index: 10;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position-y: bottom;
  }

  .full-width-content {
    width: 100%;
    padding-bottom: 0;
  }

  .content-bg {
    background-image: url("../assets/images/mountain_bg.svg");
  }

  .landing-page-content {
    background-image: none;
  }

  .navi-menu-list-item {
    padding-left: 16px;
    
    &:hover {
      background-color: $sapphire;
    }
  }
}

.footer-container {
  background: $ocean-navy;
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;

  .text-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    z-index: 0;
  }

  h6,
  a {
    color: white;
    text-transform: uppercase;
  }

  a {
    font-weight: bold;
    text-decoration: none;
  }
}

.k-header {
  
}