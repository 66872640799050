.portfolio-weights-chart {
  padding-top: 10px;
  .MuiTypography-root {
    padding-bottom: 10px;
    color: $navbar-background-color;
  }
  border: 1px solid $body-background;
  border-radius: 5px;
  margin: 1rem 0;
  background-color: white;
  .chart-container {
    margin-right: 10px;
  }
  .k-chart {
    height: 600px;
  }
}
