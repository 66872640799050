.investor-relations-route {
  max-width: 1280px;
  margin: 0 auto;
  .report-setup {
    max-width: 500px;
    margin: 32px auto;
    .single-selector {
      margin: 16px 0;
    }

    .generate-button {
      display: flex;
      flex-direction: column;
    }
  }
  .booklets-management {
    max-width: 500px;
    margin: 0 auto;
    .booklet-date {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
    }
    .available-booklets {
      margin-top: 16px;
    }
  }
  .booklet-upload {
    .global-selector {
      display: flex;
    }
    .array-container {
      margin: 32px 0;
    }
    .booklet-row {
      display: flex;
      .kendo-virtual-combobox {
        flex: 0 1 450px;
      }
      .k-datepicker {
        flex: 0 1 350px;
      }
    }
  }
  .ir-docs-route {
    h6 {
      padding: 16px 0;
    }
    .document-list {
      margin-top: 16px;
    }
  }
}
