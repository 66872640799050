.portfolio-analysis-route {
  .selectors {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 32px auto;
    .single-selector {
      margin: 8px 0;
    }
    .generate-button {
      display: flex;
      flex-direction: column;
      margin: 8px 0;
    }
  }
}
