.k-header {
  padding: 10px;
  .MuiButtonGroup-root {
    margin: 15px;
  }
  .MuiFormControl-root {
    padding-left: 20px;
    width: 400px;
    .MuiFormLabel-root {
      padding-left: 20px;
    }
  }
}
