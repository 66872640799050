.performance-tab {
  .perf-table {
  }
  .commentary-content {
    background-color: #c6e0ff;
    // padding: 1rem;
    margin: 1rem 0;
    color: $navbar-background-color;
    border-radius: 5px;
    .MuiTypography-root {
      padding-bottom: 10px;
    }
  }
  .cumulative-growth {
    margin-top: 20px;
    color: $navbar-background-color;
    border-radius: 5px;
  }
  .header {
    padding-top: 1rem;
    background-color: white;
    text-align: center;
  }
}
