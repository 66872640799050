.statistics-table-container {
  .dates-container {
    display: flex;
    align-content: center;
    .stats-date {
      margin-left: auto;
      display: flex;
      align-items: center;
      .calendar-icon {
        color: $navbar-background-color;
      }
    }
  }
  tr {
    th {
      border: 1px solid $eggshell !important;
    }

    td {
      height: 30px;
    }
  }

  .k-grid-content {
    overflow-x: hidden !important;
    border-bottom: 1px solid $eggshell;
    border-right: 1px solid $eggshell;
  }
}