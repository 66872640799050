.data-management-table-container {
  .data-management-table {
    tr:hover {
      background-color: white;
    }
    td,
    th {
      padding: 3px 0.5px;
      text-align: center;
      border-width: 0.5px 0.5px;
      border-color: #ddd;
      &:first-of-type {
        font-weight: bold;
      }
      color: $navbar-background-color;
    }

    th {
      background-color: $antarctica-color;
      border: 1px solid #ddd;
      color: white;
    }
    .k-alt {
      background-color: $table-alter-row;
      &:hover {
        background-color: $table-alter-row;
      }
    }
  }
  .k-grid .k-grid-header .k-header .k-link {
    background-color: $antarctica-color;
    height: auto;
  }

  .k-grid .k-grid-header .k-header {
    background-color: $antarctica-color;
    white-space: normal;
    text-align: center !important;
    vertical-align: middle !important;
  }
  .total-row {
    tr:last-of-type {
      font-weight: bold;
      background-color: #adadad;
      &:hover {
        background-color: #adadad;
      }
    }
  }
  .total-col {
    tr {
      td:last-of-type {
        font-weight: bold;
        background-color: #adadad;
      }
    }
  }

  .blue-heading {
    thead {
      th {
        background-color: $antarctica-light-color;
        color: white;
        &:hover {
          background-color: $antarctica-light-color;
          color: white;
        }
      }
    }
  }
  .highlight-name {
    thead {
      th:first-of-type {
        background-color: #686868;
        color: white;
        &:hover {
          background-color: #686868;
          color: white;
        }
      }
    }
  }
}
