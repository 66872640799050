.total-expense-table {
  .cell-name {
    cursor: pointer !important;
    &:hover {
      font-weight: bold;
    }
    .aaap-name {
      color: $antarctica-color;
      padding: 0;
    }
    .underlying-name {
      padding: 0;
    }
  }
  .k-grid-header {
    background-color: $antarctica-color;
    color: white;
    $hovered-text: grey;
  }

  .k-alt {
    background-color: $table-alter-row !important;
  }
  tr {
    font-size: small;
  }
  td {
    text-align: left;
    & :nth-of-type(1) {
      text-align: left;
    }
    & :nth-of-type(2) {
      text-align: left;
    }
  }

  thead {
    th {
      padding-left: 1.3rem;
      padding-right: 0.3rem;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      text-align: left;
      &:hover {
        background-color: $antarctica-light-color;
        & * {
          color: white !important;
        }
      }
      &:nth-of-type(2) {
        text-align: left;
      }
    }
    .k-sorted {
      background-color: $antarctica-light-color;
      & * {
        color: white !important;
      }
    }
  }
}
