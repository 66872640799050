.comparison-route {
  .selector-container {
    max-width: 500px;
    margin: 32px auto;
    .single-selector {
      margin: 16px 0;
    }

    .generate-button {
      display: flex;
      flex-direction: column;
    }
  }

  .comparison-container {
    display: flex;
    justify-content: center;

    .single-entry {
      flex: 1 1 700px;

      .line-ts-chart-container {
        border-radius: 5px;
      }
      .title-container {
        padding: 1rem;
        color: $navbar-background-color;
        border-radius: 5px;
      }
      .k-chart-surface {
        padding: 1.5rem;
      }
      margin: 0 10px;
    }
    .statistics-table-container {
      max-width: 1440px;
    }
  }
}
