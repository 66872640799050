.webfolio-update-route {
  .grid-container {
    width: 550px;
    margin: 0 auto;
    .toolbar {
      .update-form {
        // display: flex;
        .k-datepicker {
          margin-right: 16px;
          // width: 150px;
        }
        .k-maskedtextbox {
          margin-right: 16px;
        }
        button {
          margin-left: 96px;
        }
      }
    }
  }
}
