.image-repo-dialog {
  .MuiPaper-root {
    min-height: 746px;
  }

  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    margin: 20px 0;
  }
  .dialog-actions {
    display: flex;
    justify-content: flex-start;
  }
  .close-button {
    margin-left: auto !important;
  }

  .dialog-title {
    display: flex;
  }
  .title-button {
    margin-left: auto;
  }
}
