.exposure-analysis-route {
  .selectors {
    max-width: 500px;
    margin: 32px auto;
    .single-selector {
      margin: 16px 0;
    }

    .generate-button {
      display: flex;
      flex-direction: column;
    }
  }
  .MuiLinearProgress-root {
    height: 20px;
  }
  .aum-chart {
    max-width: 1280px;
    margin: 0 auto;
  }
}
